<template>
  <v-app light>
    <nuxt />
  </v-app>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import CommonAPI from '~/api/common'
import { setToken, unsetToken, getMemberFromCookie, getMemberFromLocalStorage, setTokenURL, unsetTokenURL } from '~/utils/auth'
export default {
  async created () {
    // console.log(process.env.NODE_ENV)
    await CommonAPI.getApiUrl()
      .then((result) => {
        if(result.apiBaseUrl) {
          setTokenURL(result.apiBaseUrl, result.apiCoinUrl, result.apiTradeUrl, result.apiTBaseUrl)
        } else {
          setTokenURL(process.env.apiBaseUrlDev, process.env.apiCoinUrlDev, process.env.apiTradeUrlDev, process.env.apiTBaseUrlDev)
        }
      })
      .catch((error) => {
        setTokenURL(process.env.apiBaseUrlDev, process.env.apiCoinUrlDev, process.env.apiTradeUrlDev, process.env.apiTBaseUrlDev)
        console.log(error)
      })
  },
}
</script>

<style>
  @import '~/assets/scss/common.scss';
</style>
