import axios from '~/utils/axios'
import { getTokenFromLocalStorage } from '~/utils/auth'

export default {
    getToken() {
        return {
            headers: {
                "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
            }
        }
    },

    // api url
    setUrl(uri) {
        const url = window.sessionStorage.api_base_url || process.env.apiBaseUrlDev
        return url + uri
    },

    // 클라이언트 목록
    async getClient(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/list')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 활성 클라이언트 전체
    async getClientAll() {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/all')
            const response = await axios.get(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 클라이언트 추가
    async addClient(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/add')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 클라이언트 수정
    async updateClient(id, payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/' + id)
            const response = await axios.put(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 클라이언트 삭제(탈퇴)
    async deleteClient(id) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/' + id)
            const response = await axios.delete(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 클라이언트 에이전트 목록
    async getClientAgent(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/agent/list')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 클라이언트 에이전트 전체
    async getClientAgentAll() {
        try {
            const token = this.getToken()
            const url = this.setUrl('/client/agent/all')
            const response = await axios.get(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 클라이언트 에이전트 저장/삭제
    async saveClientAgent(method, payload) {
        try {
            const token = this.getToken()
            let response, url
            switch (method) {
                case 'post':
                    url = this.setUrl('/client/agent/add')
                    response = await axios.post(url, payload, token)
                    break
                case 'put':
                    url = this.setUrl('/client/agent/' + payload.id)
                    response = await axios.put(url, payload, token)
                    break
                case 'delete':
                    url = this.setUrl('/client/agent/' + payload.id)
                    response = await axios.delete(url, token)
            }
            return response.data
        } catch ({ response }) {
            return response.data
        }
    }
}