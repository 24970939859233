/**
 * Global Filter 정의
 */
import Vue from 'vue'
import CommonAPI from '~/api/common'

// 회원상태
Vue.filter('_memberStatus', function (value) {
  if (!value) return ''
  let status =''
  CommonAPI._MemberStatus.forEach(element => {
    if( element.value == value ) {
      status = element.text
      return false
    }
    return true
  });
  return status
})

// 회원인증단계
Vue.filter('_memberLevelCert', function (value) {
  if (!value) return 'Lv 0 : 이메일 인증 대기중'
  let status =''
  CommonAPI._MemberLevelCert.forEach(element => {
    if( element.value == value ) {
      status = element.text
      return false
    }
    return true
  });
  return status
})

// 관리자권한
Vue.filter('_managerLevelRole', function (value) {
  if (!value) return ''
  let status =''
  CommonAPI._ManagerLevelRole.forEach(element => {
    if( element.value == value ) {
      status = element.text
      return false
    }
    return true
  });
  return status
})