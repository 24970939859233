<template>
  <v-dialog v-model="popupLoadingStatus" hide-overlay persistent width="350">
    <v-card color="primary" dark>
      <v-card-text>
        {{ popupLoadingMessageStatus | placeholder('처리중입니다...') }}
        <v-progress-linear indeterminate color="white" class="mb-0">
        </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      popupLoadingStatus: 'getPopupLoadingStatus',
      popupLoadingMessageStatus : 'getPopupLoadingMessageStatus',
    })
  },
  methods: {
    ...mapMutations([
      'SET_POPUP_LOADING_STATUS',
      'SET_POPUP_LOADING_MESSAGE_STATUS',
    ]),
  }
}
</script>
