import GameAPI from '~/api/game'
import ClientAPI from '~/api/client'
import AgencyAPI from '~/api/agency'

// TODO: 데이터량이 많을시 실시간으로 불러오는것으로 변경 해야할듯..
export const state = () => ({
    site: [],
    agent: [],
    platform: [],
    channel: [],
    betType: [],
    client: [],
    clientAgent: [],
    agency: [],
    agencySite: [],
})

export const getters = {
    getSite: (state) => state.site,
    getAgent: (state) => state.agent,
    getPlatform: (state) => state.platform,
    getChannel: (state) => state.channel,
    getBetType: (state) => state.betType,
    getClient: (state) => state.client,
    getClientAgent: (state) => state.clientAgent,
    getAgency: (state) => state.agency,
    getAgencySite: (state) => state.agencySite
}

export const mutations = {
    setSite: (state, category) => state.site = category,
    setAgent: (state, category) => state.agent = category,
    setPlatform: (state, category) => state.platform = category,
    setChannel: (state, category) => state.channel = category,
    setBetType: (state, category) => state.betType = category,
    setClient: (state, payload) => state.client = payload,
    setClientAgent: (state, payload) => state.clientAgent = payload,
    setAgency: (state, payload) => state.agency = payload,
    setAgencySite: (state, payload) => state.agencySite = payload
}

export const actions = {
    initCategories({commit}) {
        GameAPI.getAllCategories().then(response => {
            if (response.success) {
                const data = response.d.item

                let site = []
                let agent = []
                let platform = []
                let channel = []
                let betType = []

                data.forEach(item => {
                    let id = item.providerId
                    let type = item.type
                    let name = item.name

                    if (!site.find(i => i.id === id)) site.push({id: id, categories: [{value: '', text: '사이트'}]})
                    if (!agent.find(i => i.id === id)) agent.push({id: id, categories: [{value: '', text: 'AGENT'}]})
                    if (!platform.find(i => i.id === id)) platform.push({id: id, categories: [{value: '', text: '플랫폼'}]})
                    if (!channel.find(i => i.id === id)) channel.push({id: id, categories: [{value: '', text: '채널'}]})
                    if (!betType.find(i => i.id === id)) betType.push({id: id, categories: [{value: '', text: '배팅타입'}]})

                    switch (type) {
                        case 'site' :
                            site.find(i => i.id === id).categories.push({value: name, text: name})
                            break;
                        case 'agent' :
                            agent.find(i => i.id === id).categories.push({value: name, text: name})
                            break;
                        case 'platform_type' :
                            platform.find(i => i.id === id).categories.push({value: name, text: name})
                            break;
                        case 'channel' :
                            // asia game: channel game type 예외처리
                            let display = item.subName !== null && item.subName !== "" ? item.subName : name
                            channel.find(i => i.id === id).categories.push({value: name, text: display, subName: item.subName})
                            break;
                        case 'bet_type' :
                            betType.find(i => i.id === id).categories.push({value: name, text: name})
                            break;
                    }
                })

                commit('setSite', site)
                commit('setAgent', agent)
                commit('setPlatform', platform)
                commit('setChannel', channel)
                commit('setBetType', betType)
            } else {
                commit('setSite', [])
                commit('setAgent', [])
                commit('setPlatform', [])
                commit('setChannel', [])
                commit('setBetType', [])
            }
        }).catch(error => {
            console.error('initCategories error: ', error)
        })
    },

    async initClient({commit}) {
        const response = await ClientAPI.getClientAll()
        if (response.success) {
            const client = []
            response.d.item.forEach(o => {
                client.push({
                    id: o.id,
                    uid: o.uid,
                    username: o.username
                })
            })
            commit('setClient', client)
        }
    },

    async initClientAgent({commit}) {
        const response = await ClientAPI.getClientAgentAll()
        if (response.success) {
            const clientAgent = []
            response.d.item.forEach(o => {
                clientAgent.push({
                    clientId: o.amsClient,
                    agentId: o.agentId,
                    providerId: o.providerId,
                    memo: o.memo
                })
            })
            commit('setClientAgent', clientAgent)
        }
    },

    async initAgency({commit}) {
        const response = await AgencyAPI.getAgencyAll()
        if (response.success) {
            const agency = []
            response.d.item.forEach(o => {
                agency.push({
                    id: o.id,
                    uid: o.uid,
                    memo: o.memo
                })
            })
            commit('setAgency', agency)
        }
    },

    async initAgencySite({commit}) {
        const response = await AgencyAPI.getAgencySiteAll()
        if (response.success) {
            const site = []
            response.d.item.forEach(o => {
                site.push({
                    id: o.id,
                    mid: o.agentId,
                    sid: o.agentSite,
                    name: o.name
                })
            })
            commit('setAgencySite', site)
        }
    }
}