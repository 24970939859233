import Vue from 'vue'

Vue.directive('numeric', {
    bind(el, binding, vnode) {
        // 숫자만
        const onlyNumber = (e) => {
            e = e ? e : window.event;
            let keyCode = (e.which) ? e.which : e.keyCode;
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (keyCode === 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (keyCode === 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (keyCode === 88 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
                (keyCode >= 35 && keyCode <= 39)) {
                // let it happen, don't do anything
                return
            }

            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (keyCode < 48 || keyCode > 57)) && (keyCode < 96 || keyCode > 105)) {
                e.preventDefault()
            }
        }

        // 한글 대응 ㅠ
        const Hangeul = (e) => {
            if (e.target.value) {
                e.target.value = e.target.value.replace(/\D/g, '')
                vnode.elm.dispatchEvent(new CustomEvent('keyup'))
            }
        }

        el.addEventListener('keydown', onlyNumber)
        el.addEventListener('keyup', Hangeul)
    }
})