<template>
  <v-dialog
    v-model="popupDialogStatus"
    persistent 
    max-width="350">
    <v-card>
      <v-card-title class="headline">NOTICE</v-card-title>

      <v-card-text class="body-1">
        {{ popupDialogMessageStatus }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <!-- <v-btn
          color="green darken-1"
          flat="flat"
          @click="dialog = false" >
          Disagree
        </v-btn> -->

        <v-btn
          color="green darken-1"
          flat="flat"
          @click="closeAgree" >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  data() {
    return {
      dialog: true
    }
  },
  computed: {
    ...mapGetters({
      popupDialogStatus: 'getPopupDialogStatus',
      popupDialogMessageStatus: 'getPopupDialogMessageStatus',
    })
  },
  methods: {
    closeAgree() {
      this.SET_POPUP_DIALOG_STATUS(false)
      this.SET_POPUP_DIALOG_MESSAGE_STATUS('')
    },
    ...mapMutations([
      'SET_POPUP_DIALOG_STATUS',
      'SET_POPUP_DIALOG_MESSAGE_STATUS',
    ]),
  }
}
</script>
