export const state = () => ({
    path: null,
    payload: null,
    amounts: {
        inTotal: 0,
        outTotal: 0,
    }
})

export const getters = {
    getPath: (state) => state.path,
    getPayload: (state) => state.payload,
    getAmounts: (state) => state.amounts
}

export const mutations = {
    setPath: (state, path) => state.path = path,
    setPayload: (state, payload) => state.payload = payload,
    setAmounts: (state, amounts) => {
        if (amounts === null) {
            state.amounts.inTotal = 0
            state.amounts.outTotal = 0
        } else {
            state.amounts = amounts
        }
    }
}

