import jwtDecode from 'jwt-decode'
import Cookie from 'js-cookie'

const getQueryParams = () => {
  const params = {}
  window.location.href.replace(/([^(?|#)=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
    params[$1] = $3
  })
  return params
}

export const extractInfoFromHash = () => {
  if (process.SERVER_BUILD) return
  const { id_token, state } = getQueryParams()
  return {
    token: id_token,
  }
}

export const setToken = (token) => {
  if (process.SERVER_BUILD) return
  // console.log(jwtDecode(token))
  let member = jwtDecode(token).member ? jwtDecode(token).member : undefined
  if(member) {
    member.token = token
    window.sessionStorage.setItem('token', token)
    window.sessionStorage.setItem('member_id', JSON.stringify(member.member_id))
    window.sessionStorage.setItem('member_uid', JSON.stringify(member.member_uid))
    window.sessionStorage.setItem('member_name', JSON.stringify(member.member_name))
    window.sessionStorage.setItem('level_role', JSON.stringify(member.level_role))
    window.sessionStorage.setItem('expires', Number(JSON.stringify(member.expires))*1000)
    Cookie.set('token', token)
  }
  return member
}

export const unsetToken = () => {
  if (process.SERVER_BUILD) return
  window.sessionStorage.removeItem('token')
  window.sessionStorage.removeItem('member_id')
  window.sessionStorage.removeItem('member_uid')
  window.sessionStorage.removeItem('member_name')
  window.sessionStorage.removeItem('level_role')
  window.sessionStorage.removeItem('expires')
  Cookie.remove('token')
  window.sessionStorage.setItem('logout', Date.now())
}

export const getMemberFromCookie = (req) => {
  if (!req.headers.cookie) return
  const jwtCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith('token='))
  if (!jwtCookie) return
  const jwt = jwtCookie.split('=')[1]
  // return jwtDecode(jwt)
  return jwt
}

export const getMemberFromLocalStorage = () => {
  const json = {
    token: window.sessionStorage.token ? window.sessionStorage.token : undefined,
    member_id: window.sessionStorage.member_id ? JSON.parse(window.sessionStorage.member_id) : undefined,
    member_uid: window.sessionStorage.member_uid ? JSON.parse(window.sessionStorage.member_uid) : undefined,
    member_name: window.sessionStorage.member_name ? JSON.parse(window.sessionStorage.member_name) : undefined,
    level_role: window.sessionStorage.level_role ? JSON.parse(window.sessionStorage.level_role) : undefined,
  }
  return json
}

export const getTokenFromLocalStorage = () => {
  const json = window.sessionStorage.token ? window.sessionStorage.token : undefined
  return json
}

export const getLanguageFromLocalStorage = () => {
  const json = window.sessionStorage.language ? window.sessionStorage.language : undefined
  return json
}

export const setTokenURL = (apiBaseUrl) => {
  if (process.SERVER_BUILD) return
  window.sessionStorage.setItem('api_base_url', apiBaseUrl)
}

export const unsetTokenURL = () => {
  if (process.SERVER_BUILD) return
  window.sessionStorage.removeItem('api_base_url')
}
