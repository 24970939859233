import axios from '~/utils/axios'
import { getTokenFromLocalStorage, getLanguageFromLocalStorage } from '~/utils/auth'

export default {
  registerManager: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/register', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  signIn: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/signIn', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
    })
  },

  getManagerInfo: function (params, payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/getManagerInfo', params, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
    })
  },

  getImgInfoList: function (payload) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url+ '/files/getImgInfoList', payload , { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        }})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  saveImgInfo: function (payload) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url+ '/files/saveImgInfo', payload , { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        }})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  updateImgInfo: function (payload) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url+ '/files/updateImgInfo', payload , { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        }})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },
  deleteImgInfo: function (payload) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url+ '/files/deleteImgInfo', payload , { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        }})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },


  uploadFile: function (file, provider, payload = null) {
    if( !file ) {
      return false
    }
    return new Promise((resolve) => {
      let formData = new FormData()
      formData.append('file', file, file.name)
      axios.post(window.sessionStorage.api_base_url+ '/files/uploadFile/'+ provider, formData , { headers: { "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : "",
          "Content-Type": "multipart/form-data",
        }})
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },


  changeAgencyNewPassword: function (params, payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/changeAgencyNewPassword', params, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
    })
  },

  checkPwd: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/checkPwd', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },
  getManagers: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/getManagers', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  editManager: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/editManager', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  writeConnectionIp: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/writeConnectionIp', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  editConnectionIp: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/editConnectionIp', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  getConnectionIps: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/getConnectionIps', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  deleteConnectionIp: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/deleteConnectionIp/'+payload.connection_ip_id, {}, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  writeBlockingIp: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/writeBlockingIp', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  editBlockingIp: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/editBlockingIp', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  getBlockingIps: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/getBlockingIps', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  deleteBlockingIp: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/deleteBlockingIp/'+payload.blocking_ip_id, {}, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  setMaintenance: function (status, payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_trade_url + '/controllers/setMaintenance/'+ status, payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  getMaintenance: function (payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_trade_url + '/controllers/getMaintenance', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  getActivityLog: function(payload = null) {
    return new Promise((resolve) => {
      axios.post(window.sessionStorage.api_base_url + '/managers/activityLog', payload, { headers: {
          "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
          "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
        } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
    })
  }

}
