<template>
  <div v-if="popupOnOff" class="modal today">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <button class="modal_close" @click="closePopup"><i class="xi-close-thin"></i></button>
        </div>
        <div class="modal-body">
          <em>새로운 로그인 알림</em>
          <div class="text_con">
            {{ '회원정보 : ' + item.userName +'(' + item.uid + ' / ' + item.memberId + ')' }}<br>
            {{ '로그인 일시 : ' }}{{ item.startDate | moment('YYYY-MM-DD HH:mm') }}
          </div>
        </div>
        <div class="modal-footer">
          <button @click="closePopup">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookie from 'js-cookie'
    export default{
      props: {
        item: {
          type:Object,
          default: function () { return {}}
        },
      },
      data(){
        return {
          popupOnOff: true,
          chdayOnly2: 0,
        }
      },
      mounted (){},
      methods: {
        closePopup () {
              this.popupOnOff = false
          },
        closePopupForever () {
          Cookie.set('popup'+ this.item.id, 'true')
          this.popupOnOff = false
        },
      },
    }
</script>

<style lang="scss">
  .modal.today{z-index:99 !important;}
</style>

<style lang="scss" scoped>
  // 모탈 공용
  .modal {position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0, 0, 0, 0.05);display:table;transition: opacity .3s ease;}
  .modal-wrapper{display:table-cell;vertical-align:middle;}
  .modal-container {position:relative;width: 460px;background:#fff;border-radius:0;box-shadow: 0 3px 15px 5px rgba(0, 0, 0, .05);transition: all .3s ease;margin: 0 auto;}
  .modal-header{position:relative;display:flex;flex-direction:row;justify-content:flex-end;align-items:center;height:30px;border:0;padding:0;}
  .modal-header .modal_close{width:20px;height:20px;margin-right:15px;margin-top:15px;transition:all 0.2s;}
  .modal-header .modal_close i{font-size:24px;color:#aaa;}

  .modal-body{position:relative;padding:0 !important;}
  .modal-body em{display:inline-block;width:100%;line-height:36px;text-align:center;background:rgba(255,255,255, 0.05);color:#3578f6;font-size:20px;font-weight:400;}

  .text_con{font-size:15px;text-align:center;color:#333;font-weight:400 !important;line-height:1.8;padding:20px;}

  .modal-footer{display:flex;justify-content:center;border-top:none;padding:20px;margin:0;}
  .modal-footer button{display:inline-flex;justify-content:center;align-items:center;width:50%;height:40px;background:#eee;border-radius: 20px;transition:all .3s;}
  .modal-footer button:hover{background:#e1e1e1;}
  .modal-enter, .modal-leave {opacity: 0;}


  @media screen and (max-width:768px){
    .modal {width:calc(100% - 30px);margin:0 15px;}
    .modal-container {width: 100%;}
    .modal-header{height:60px;background-size:auto 30px !important;padding:0;}
    .body_text{line-height:1.5;padding:10px 0;}
    .modal-footer {padding:10px 0;margin:0 15px;}
  }
</style>
