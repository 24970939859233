import { getTokenFromLocalStorage } from '~/utils/auth'

export default function (context) {
  context.$axios.defaults.baseURL = window.sessionStorage.api_base_url

  context.$axios.onRequest(config => {
    // 모든 Request Header 에 Token 값 추가
    // console.log('token : ' + getTokenFromLocalStorage())
    config.headers.common['Cryptolization'] = !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : ''

  })

  context.$axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      // context.redirect('/400')
    }
  })
}
