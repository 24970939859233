import { getTokenFromLocalStorage, unsetToken } from '~/utils/auth'

function requireAuth (store, to, from, next) {

  var nextSave = next
  // 회원정보 업데이트 진행중..
  if (store.getters.getMemberLoadStatus() != 2) {
    store.watch( store.getters.getMemberLoadStatus, () => {
      if (store.getters.getMemberLoadStatus() == 2) {
        if(getTokenFromLocalStorage()) {
          nextSave()
        } else {
          proceed(store, nextSave)
        }
      }
    })
  // 회원정보 업데이트 완료시..
  } else {
    proceed(store, nextSave)
  }
}

function proceed (store, next) {
// Store 에 회원정보가 있으면..
  if(getTokenFromLocalStorage()) {
    var expires = window.sessionStorage.expires ? window.sessionStorage.expires : 0
    var now = + new Date()
    now = now  //+ 60 * 60 * 1000 * 9
    //console.log('expires : ' + expires)
    //console.log('nowtime : ' + now)
    if(expires - now > 0) {
      next()
    } else {
      alert("토큰이 만료되었습니다. 다시 로그인 해 주세요")
      store.dispatch('setMember', null)
      unsetToken()
      next({ path: '/' })
      store.commit('SET_POPUP_LOGIN_STATUS', 2)
    }
    // Store 에 회원정보가 없으면..
  } else {
    next({ path: '/' })
    store.commit('SET_POPUP_LOGIN_STATUS', 2)
  }

}

export default function ({ store, app }) {
  app.router.beforeEach((to, from, next) => {

    const path = [
      '/',
    ]
    const pathDevelop = [
      // '/manager/manager',
    ]

    // 개발 완료 전까지 비활성화
    if(pathDevelop.findIndex(url => url == to.fullPath) > -1) {
      //next('/')
    } else {
      // 인증 페이지
      if(path.findIndex(url => url == to.fullPath) == -1) {
        requireAuth(store, to, from, next)
        // 비인증 페이지
      } else {
        next()
      }
    }

  })
}
