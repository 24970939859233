<template>
  <v-app>
    <v-navigation-drawer :mini-variant="miniVariant" :clipped="clipped" :width="200" v-model="drawer" fixed app>
      <LeftMenu />
    </v-navigation-drawer>

    <v-toolbar :clipped-left="clipped" fixed app>
      <v-toolbar-side-icon @click="drawer = !drawer" />
      <h2>{{ pageTitle }}</h2>
      <div v-if="pageCount" class="board_num mr-1 ml-2">{{ pageCount | currency('', 0) }}</div>
      <v-spacer />
      <div class="welcome hidden-sm-and-down mr-1 ml-2">
        <span class="iconbox"><v-icon small dark>person</v-icon></span>
        &nbsp;{{ member.member_uid }} | {{ member.member_name }}(<b>{{ member.level_role | _managerLevelRole }}</b>)
      </div>
      <v-toolbar-items class="top_menu hidden-sm-and-down">
        <v-btn flat @click="dialog=true">비밀번호 변경</v-btn>
        <v-btn flat @click="logOut">로그아웃</v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-content style="background:#e8e8e8;">
      {{ checkAuth() }}
      <nuxt />
    </v-content>
    <popup-loading />
    <popup-dialog />
    <p v-for="(item, index) in popupList" :key="index">
      <popup-today :item="item" />
    </p>

    <!-- 모달창 -->
    <v-dialog v-model="dialog" max-width="400px">
      <v-card class="dialog_type_1">
        <v-card-title class="blue-grey darken-2">
          <span class="headline ml-3">관리자 비밀번호 변경</span><v-spacer /><v-btn class="mr-0 modalclose" color="grey darken-3" fab flat @click.native="dialog = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container fluid grid-list-md>
            <v-layout row wrap class="box_contents_write_1 padding-15px-bottom">
              <div class="input_title padding-5px-left">새로운 비밀번호</div>
              <v-flex lg9>
                <form>
                  <v-text-field v-model="newPassword" :append-icon="show ? 'visibility_off' : 'visibility'" :rules="[rules.required, rules.min]" :type="show ? 'text' : 'password'" label="" class="input_i pw_change" autocomplete="off" counter @click:append="show = !show"></v-text-field>
                </form>
              </v-flex>
              <v-flex lg3>
                <v-btn class="btn_m blue-grey width-100 bh_30" @click="setNewPassword">변경하기</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn_m blue-grey lighten-3" @click="dialog = false">닫기</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 모달창 end -->

    {{ checkSession() }}

  </v-app>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import CommonAPI from '~/api/common'
import ManagerAPI from '~/api/manager'
import { setToken, unsetToken, getMemberFromCookie, getMemberFromLocalStorage, setTokenURL, unsetTokenURL } from '~/utils/auth'
import LeftMenu from '~/components/common/LeftMenu.vue'
import PopupLoading from '~/components/common/PopupLoading'
import PopupToday from '~/components/common/PopupToday'
import PopupDialog from '~/components/common/PopupDialog'

import { createAPI } from '~/api/firestore'
const api = createAPI("")
const firestore = api.firestore()

export default {
  components: {
    LeftMenu,
    PopupLoading,
    PopupDialog,
    PopupToday,
  },
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      miniVariant: false,
      dialog: false,
      show: false,
      newPassword: '',
      popupList: [],
      rules: {
        required: value => !!value || '입력해 주세요.',
        min: v => v.length >= 10 || '최소 10글자 이상',
      },
      alarm: {
      },
      unsubscribeNodeStatus: null,
    }
  },
  computed: {
    ...mapGetters({
      pageTitle : 'getPageTitle',
      pageCount : 'getPageCount',
      member : 'getMember'
    }),
  },
  async created () {
    await CommonAPI.getApiUrl()
      .then((result) => {
        if(result.apiBaseUrl) {
          setTokenURL(result.apiBaseUrl)
        } else {
          setTokenURL(process.env.apiBaseUrlDev)
        }
      })
      .catch((error) => {
        setTokenURL(process.env.apiBaseUrlDev)
        console.log(error)
      })

    const loggedMember = getMemberFromLocalStorage()
    //console.log(loggedMember)
    // LocalStorage 에 회원정보가 있으면..
    if (loggedMember.token) {
      await this.$store.dispatch('setMember', loggedMember)
    } else {
      await this.$store.dispatch('setMember', null)
    }

    if(this.$store.getters.getMember.token) {

    } else {
      await this.$router.push('/')
    }
  },
  mounted() {

  },
  destroyed () {
    if(typeof this.unsubscribeNodeStatus == 'function') this.unsubscribeNodeStatus()
  },
  methods: {
    checkSession: function() {
      let timestamp = new Date().getTime() + '000'
      if( window.sessionStorage.expires < timestamp ) {
        alert("토큰이 만료되었습니다. 다시 로그인 해 주세요")
        this.logOut()
      }
    },
    checkAuth: function() {
      if( !this.$levelCheck('R')) {
        this.$router.push('/error')
      }
    },
    logOut: function () {
      this.$router.replace('/')
      this.$store.dispatch('setMember', null)
      unsetToken()
    },
    setNewPassword() {
      if( this.newPassword.length < 10 ) {
        alert("비밀번호를 10글자 이상 입력하세요")
        return false
      } else {
        ManagerAPI.changeAgencyNewPassword({
          new_upw : this.newPassword
        }).then(response =>{
          alert(response.msg)
          this.newPassword = ""
          this.dialog = false
        })
      }
    },
    subscribeAlarmCoinInout:function() {
      // this.unsubscribeNodeStatus = firestore.collection("alarm").doc('coin').collection('inout').where('alarm_exception','==',false).where('status','==','W')
      //   .onSnapshot((querySnapshot) => {
      //     this.alarm.coin_inout = querySnapshot.size
      //     this.checkAlarm()
      //   })
    },
    checkAlarm() {

    }
  },
}
</script>

<style>
@import '~/assets/scss/common.scss';
@import '~/assets/scss/style.scss';
@import '~/assets/scss/responsive.scss';

/* 레이아웃 */
/*html{min-width:1440px !important;overflow-x:scroll !important;}*/
/* main.v-content{padding:51px 0 0 200px !important;background:#f4f6f8 url('~assets/img/bg_sub.png') !important;background-size:4px auto !important;} */
main.v-content{margin-top:-10px;background:#f4f6f8 url('~assets/img/bg_sub.png') !important;background-size:4px auto !important;}
.container{padding:6px 10px 10px !important;}
/*.container.grid-list-md *:not(:only-child) .layout:first-child {margin-top:0 !important;}*/

/* 상단메뉴 */
/* nav.v-toolbar{padding-left:200px !important;border-bottom:0;box-shadow:none;border-bottom:1px solid #e5e5e5;background-color:#ffffff;} */
nav.v-toolbar{border-bottom:0;box-shadow:none;border-bottom:1px solid #e5e5e5;background-color:#ffffff;}
nav .v-toolbar__content{height:51px !important;padding:0 10px !important;}
nav .v-toolbar__content h2{display:inline-block;font-size:17px;color:#444f5c;font-weight:400;letter-spacing:-0.5px;margin:0 !important;padding:0 !important;}
.board_num{display:inline-block;height:26px;line-height:26px !important;border:0 !important;background:#414b58 !important;font-size:12px !important;color:#fff;border-radius:12px;padding:0 8px !important;text-align:center !important;}
.welcome{display:inline-block;height:26px;line-height:26px !important;color:#444f5c;font-weight:400;font-family:'Noto Sans KR';border:0 !important;background:none !important;font-size:12px !important;border-radius:12px;padding:0 8px !important;text-align:center !important;}
.welcome i{color:#444f5c !important;}

/* 탑링크 */
.top_menu .v-btn{width:auto !important;min-width:auto !important;padding:0 !important;margin-left:10px !important;}
.top_menu .v-btn__content{font-size:12px !important;font-weight:400;color:#888;font-family:'Noto Sans KR';}

/* Vuetify data table loading linear margin fix */
.v-datatable__progress th .v-progress-linear {
  margin: -2px 0 0 0 !important;
}

/* Vuetify v select padding fix */
.v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 0 0 12px;
}

@media only screen and (min-width: 1904px){
  /* 레이아웃 */
  .container{max-width:100% !important;}
}

</style>
