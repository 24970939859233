import axios from '~/utils/axios'

export default {

  getBaseCodes: function (payload = null) {
    return new Promise((resolve) => {
      resolve({
        success: true,
        msg: '',
        trace: '',
        d: {
          item: [
            { code: 'CODE10S', value: '시작' },
            { code: 'CODE10P', value: '진행중' },
            { code: 'CODE10F', value: '종료' },
            { code: 'CODE10C', value: '취소' },
          ],
          total: 0,
        },
      })

      // axios.post(process.env.apiBaseUrl + '/settings/getBaseCode', payload)
      //   .then(response => {
      //     resolve(response.data)
      //   })
      //   .catch(error => {
      //     resolve(error)
      //   })
    })
  },

}