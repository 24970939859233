import axios from '~/utils/axios'
import { getTokenFromLocalStorage, getLanguageFromLocalStorage } from '~/utils/auth'

export default {

    getBetLogList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getBetLogList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },
    getBetLogData: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getBetLogData', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error)
            })
        })
    },
    getBetLogSportsList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getSportsBetLogs', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error)
            })
        })
    },

    getBetLogSportsHistoryById: function (id) {
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getSportsBetLogHistory/'+ id, {}, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error)
            })
        })
    },

    updateBetLogSports(param) {
        const params = new URLSearchParams()
        params.append('param', param)
        return new Promise((resolve) => {
            axios.post(process.env.apiSportsUrl, params, {})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                resolve(error)
            })
        })
    },
  getGameIdLogList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getGameIdLogList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getCreateUserList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getCreateUserList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getCheckUserList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getCheckUserList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getTransferList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getTransferList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getStartGameList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getStartGameList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getGetHistoryList: function(params){
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/inquiry/getGetHistoryList', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getAllCategories: function() {
        return new Promise((resolve) => {
            axios.get(window.sessionStorage.api_base_url + '/setting/categories', { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getCategoryById: function(id) {
        return new Promise((resolve) => {
            axios.get(window.sessionStorage.api_base_url + '/setting/categories/' + id, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getCategories: function(params) {
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/setting/categories', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    addCategory: function(params) {
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/setting/categories/add', params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    putCategories: function(id, params) {
        return new Promise((resolve) => {
            axios.put(window.sessionStorage.api_base_url + '/setting/categories/' + id, params, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    deleteCategories: function(id) {
        return new Promise((resolve) => {
            axios.delete(window.sessionStorage.api_base_url + '/setting/categories/' + id, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    saveCategory: function () {
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/setting/categories/save', {}, { headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    }

}
