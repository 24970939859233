import axios from '~/utils/axios'
import { getTokenFromLocalStorage } from '~/utils/auth'

export default {
    getToken() {
        return {
            headers: {
                "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
            }
        }
    },

    // api url
    setUrl(uri) {
        const url = window.sessionStorage.api_base_url || process.env.apiBaseUrlDev
        return url + uri
    },

    // 에이전시 목록
    async getAgency(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/list')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 활성 에이전시 전체
    async getAgencyAll() {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/all')
            const response = await axios.get(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 추가
    async addAgency(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/add')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 수정
    async updateAgency(id, payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/' + id)
            const response = await axios.put(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 삭제(탈퇴)
    async deleteAgency(id) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/' + id)
            const response = await axios.delete(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 사이트 목록
    async getAgencySite(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/site/list')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 활성 에이전시 사이트 전체
    async getAgencySiteAll() {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/site/all')
            const response = await axios.get(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 사이트 추가
    async addAgencySite(payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/site/add')
            const response = await axios.post(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 사이트 수정
    async updateAgencySite(id, payload) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/site/'+ id)
            const response = await axios.put(url, payload, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    },

    // 에이전시 사이트 삭제
    async deleteAgencySite(id) {
        try {
            const token = this.getToken()
            const url = this.setUrl('/agency/site/'+ id)
            const response = await axios.delete(url, token)
            return response.data
        } catch ({ response }) {
            return response.data
        }
    }
}