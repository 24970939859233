<template>
  <div class="box_left_menu">
    <div class="leftlogo"><nuxt-link :to="$store.state.member.level_role > 10 ? '/main' : '/game/betLog' "><img src="~assets/img/logo.svg"></nuxt-link></div>
    <!--<div class="leftlogo"><nuxt-link to="/main"><img src="~assets/img/logo.png"></nuxt-link></div>-->
    <v-list>
      <v-list-group v-for="gnv in filterMainMenu(gnvs)" v-model="gnv.active" :key="gnv.title" :prepend-icon="gnv.action" no-action>

        <v-list-tile slot="activator" :to="gnv.to">
          <v-list-tile-action>
            <v-icon v-html="gnv.icon" />
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ gnv.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile v-for="subItem in filterMenu(gnv.submenu)" :key="subItem.title" :to="subItem.to">
          <v-list-tile-content>
            <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action>
            <v-icon>{{ subItem.action }}</v-icon>
          </v-list-tile-action>
        </v-list-tile>

      </v-list-group>
    </v-list>
  </div>
</template>

<script>
  import CommonAPI from '~/api/common'
  export default {
    data() {
      return {
        gnvs: CommonAPI._Gnvs,
        gnvsOrigin: CommonAPI._GnvsOrigin
      }
    },
    computed: {
    },
    created () {
      this.$store.dispatch('setBaseCode')
    },
    mounted() {
      this.checkPermission()
      // console.log(`validAgency -> window.sessionStorage.agency_type`, this.gnvs)
    },
    methods: {
      checkPermission(){
        this.gnvs =[];
        this.gnvsOrigin.forEach(e => {
          this.gnvs.push(e)
        })
      },
      validAgency(agencyType) {
        if( agencyType === null || agencyType === undefined ) return true
        else {
          return Number(window.sessionStorage.agency_type) < agencyType
        }
      },
      validSubmenu(admin) {
        if( admin === null || admin === undefined ) return true
        else {
          return admin
        }
      },
      validLevelRole(role) {
        return (role && role <= Number(window.sessionStorage.level_role)) || false
      },

      // 서브메뉴
      filterMenu: function (presets) {
        if( !presets ) return []
        return presets.filter((preset) => {
          if( this.$isSuperAgency()) {
            return preset.menu
          } else {
            // return preset.menu && this.validAgency(preset.agencyType) && this.validSubmenu(preset.admin)
            return preset.menu && this.validSubmenu(preset.admin) && this.validLevelRole(preset.role)
          }
        })
      },

      // 메인메뉴
      filterMainMenu: function (presets) {
        if( !presets ) return []
        return presets.filter((preset) => {
          if( this.$isSuperAgency()) {
            return true
          } else {
            return preset.admin === false && this.validLevelRole(preset.role)
          }
        })
      },
    }
  }
</script>

<style>
.box_left_menu{height:100%;background:linear-gradient(to top, #414b58, #133b40);}

.leftlogo{height:160px;text-align:center;}
.leftlogo img{width:140px;margin-top:15px;}

.box_left_menu .v-list{background:none;}

 /* 메뉴 */
.box_left_menu .v-list__tile{height:40px;padding: 0 15px !important;}
.box_left_menu .v-list__tile__action{min-width:24px;}
.box_left_menu .v-list__tile__action i{font-size:13px !important;color:#fff !important;}
.box_left_menu .v-list__tile__title{font-family: "Noto Sans KR";font-weight:300;font-size:13px !important;color:#fff;}
.box_left_menu .theme--light.v-icon{font-size:14px;color:#fff;}

/* 대시보드 화살표 숨김 */
.box_left_menu .v-list__group:nth-child(1) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(2) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(3) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(4) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(5) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(6) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(7) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(8) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(9) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(10) .v-list__group__header__append-icon{display:none !important;}
.box_left_menu .v-list__group:nth-child(11) .v-list__group__header__append-icon{display:none !important;}

/* 1뎁스 오버 */
.box_left_menu .v-list__group__header--active{background:none !important;}
.box_left_menu .v-list__group:before, .v-list__group:after{background:none !important;}

/* 2뎁스 */
.box_left_menu .v-list__group__items--no-action .v-list__tile__content{width:100%;}
.box_left_menu .v-list__group__items--no-action .v-list__tile__content {font-size:12px;color:#b2b5b9;}
.box_left_menu .v-list__group__items--no-action .v-list__tile{height:36px;padding:0 0 0 40px !important;background:none !important;}
.box_left_menu .v-list__group__items--no-action .v-list__tile .v-list__tile__title{font-size:12px !important;color:#b2b5b9 !important;}
/* 2뎁스 오버 */
.box_left_menu .v-list__group__items--no-action .v-list__tile--active{height:36px;background:#47505d !important;border-top:1px solid #535d69;border-bottom:1px solid #535d69;}
.box_left_menu .v-list__group__items--no-action .v-list__tile--active .v-list__tile__title {font-size:12px !important;color:#fff !important;}
</style>
