import Vue from 'vue'
import Validator from 'vee-validate'
import en from 'vee-validate/dist/locale/en'
import ko from 'vee-validate/dist/locale/ko'
import br from 'vee-validate/dist/locale/pt_BR'
import pt from 'vee-validate/dist/locale/pt_PT'

Vue.use(Validator, {
  fieldsBagName: 'veeFields',
  inject: true,
  dictionary: {
    en : en,
    ko : ko,
    br : br,
    pt : pt,
  },
  locale: 'ko',
})
