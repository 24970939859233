import Vue from 'vue'
import API from '~/api'

export const state = () => {
    return {
        i18n: { locale: 'en', },
        memberLoadStatus: 2, // 0: 최초로딩, 1: 로딩중, 2: 로딩완료
        member: {
            token: null,
            member_id: null,
            level_cert: null,
            agency_id: null,
            agency_type: null,
            level_role: null,
        },
        pageTitle: '',
        pageCount: 0,
        code: null,

        // 0: 비활성화, 1: 로딩중, 2: 로딩완료
        popupLoginStatus: 0,
        popupLoadingStatus: false,
        popupLoadingMessageStatus: '',
        popupDialogStatus: false,
        popupDialogMessageStatus: '',
        popupMemberStatus: 0,
        popupMemberId: null,
        popupIsManager: null,

        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
    }
}

// params: state, getters
export const getters = {
    getLocale: (state) => { return function () { return state.i18n.locale } },
    isAuthenticated: (state) => !!state.member.token,
    getMemberLoadStatus: (state) => { return function () { return state.memberLoadStatus } },
    getMember: (state) => state.member,

    getPageTitle: (state) => state.pageTitle,
    getPageCount: (state) => state.pageCount,
    getBaseCode: (state) => state.code,

    getPopupLoadingStatus : (state) => state.popupLoadingStatus,
    getPopupLoadingMessageStatus : (state) => state.popupLoadingMessageStatus,
    getPopupDialogStatus : (state) => state.popupDialogStatus,
    getPopupDialogMessageStatus : (state) => state.popupDialogMessageStatus,
}

// 동기적 로직 정의 params: state, payload
// store.commit('SET_MEMBER_LOAD_STATUS', 1);
export const mutations = {
    SET_LOCALE: (state, locale) => state.i18n.locale = locale || 'en',
    SET_MEMBER_LOAD_STATUS: (state, status) => state.memberLoadStatus = status,
    SET_MEMBER: (state, member) => state.member = member,
    SET_PAGE_TITLE: (state, title) => state.pageTitle = title,
    SET_PAGE_COUNT: (state, count) => state.pageCount = count,
    SET_BASE_CODE: (state, code) => state.code = code,

    SET_POPUP_LOGIN_STATUS: (state, status) => state.popupLoginStatus = status,
    SET_POPUP_LOADING_STATUS: (state, status) => state.popupLoadingStatus = status,
    SET_POPUP_LOADING_MESSAGE_STATUS: (state, status) => state.popupLoadingMessageStatus = status,
    SET_POPUP_DIALOG_STATUS: (state, status) => state.popupDialogStatus = status,
    SET_POPUP_DIALOG_MESSAGE_STATUS: (state, status) => state.popupDialogMessageStatus = status,

    SET_POPUP_MEMBER_STATUS: (state, status) => state.popupMemberStatus = status,
    SET_POPUP_MEMBER_ID: (state, status) => state.popupMemberId = status,
    SET_POPUP_IS_MANAGER: (state, status) => state.popupIsManager = status,

    SET_SOCKET: (state, status) => state.socket = status,

    SOCKET_ONOPEN (state, event)  {
        console.log('SOCKET_ONOPEN: ', state, status)
        Vue.prototype.$socket = event.currentTarget
        state.socket.isConnected = true
        Vue.prototype.$socket.send(
            JSON.stringify({
                type: 'userInfo',
                value: 'admin'
            })
        );
        Vue.prototype.$socket.send(
            JSON.stringify({
                type: 'clientConnected',
                value: 'ok'
            })
        );
    },
    SOCKET_ONCLOSE (state, event)  {
        // console.log('SOCKET_ONCLOSE', state, event)
        state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event)  {
        // console.log('SOCKET_ONERROR:', state, event)
    },
    SOCKET_ONMESSAGE (state, message)  {
        // console.log('SOCKET_ONMESSAGE:', state, message)
        state.socket.message = message
    },
    SOCKET_RECONNECT(state, count) {
        // console.info('SOCKET_RECONNECT: ', state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true;
    },
}

// 비동기적 로직 정의 params: context
// store.dispatch('loadMember', {1, 2});
export const actions = {
    setBaseCode ({ commit }, payload) {
        API.getBaseCodes()
        .then((response) => {
            commit('SET_BASE_CODE', response.d.item)
        })
        .catch((error) => {

        })
    },

    setMember ({ commit }, payload) {
        // commit('SET_MEMBER_LOAD_STATUS', 1)
        if(payload) {
            commit('SET_MEMBER', payload)
        } else {
            commit('SET_MEMBER', {
                token: null,
                member_id: null,
                member_uid: null,
                member_name: null,
                level_cert: null,
                level_trade: null,
                level_role: null,
            })
        }
        commit('SET_MEMBER_LOAD_STATUS', 2)
    },

    setPopupMember ({ commit }, member_id) {
        commit('SET_POPUP_MEMBER_STATUS', 1)
        commit('SET_POPUP_MEMBER_ID', Number(member_id))
    },

    setPopupManager ({ commit }, is_manager) {
        commit('SET_POPUP_IS_MANAGER', is_manager)
    },

    // 서버사이드에서 context 와 함께 호출!!
    nuxtServerInit (context, { req }) {
        console.log('nuxtServerInit')
        if (req.session.member) {

        } else {

        }
    }
}
