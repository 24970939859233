import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDsWwXR1hkfBbbK_YLseTqrsdU1Mo4zVZI",
  authDomain: "techoption-b2c61.firebaseapp.com",
  databaseURL: "https://techoption-b2c61.firebaseio.com",
  projectId: "techoption-b2c61",
  storageBucket: "techoption-b2c61.appspot.com",
  messagingSenderId: "430540539485",
  appId: "1:430540539485:web:8dcd4bae47443057ca6fb4",
  measurementId: "G-T38ZZ5YPEK"
};


export function createAPI ({ version }) {
  let api;
  // this piece of code may run multiple times in development mode,
  // so we attach the instantiated API to `process` to avoid duplications
  if (process.__API__) {
    api = process.__API__;
  } else {
    api = process.__API__ = firebase.initializeApp(firebaseConfig);
    api.onServer = true
  }
  return api
}
